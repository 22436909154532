export const bannerFormatsFin = [
  {
    Category: "Fin Test",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "160x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
    "Fin specs test"  
  }
  
]