export const bannerFormats = [
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "160x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "300x250",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "300x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "728x90",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "930x180",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "930x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "320x160",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default”\n All mobile material should be coded in MRAID to ensure the material functions fully in the chosen apps/mobile sites. - - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Display Std. Formats",
    Media: "Standard Banners",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "320x320",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default”\n All mobile material should be coded in MRAID to ensure the material functions fully in the chosen apps/mobile sites. - - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Other display formats",
    Media: "Mobile Swipecube",
    Format: "Mobile Swipecube",
    Type: "Display",
    Dimensions: "320x160",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "2-5 banners  //    In case of different landing pages per cube side, these and potential UTM need to be implemented in the creative code by the agency. See guidelines for clickTag and exit events in comment column. An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB (total)",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec.",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Landing page per cube side and UTM is implemented by creative agency in different exit events  -   Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default”\n All mobile material should be coded in MRAID to ensure the material functions fully in the chosen apps/mobile sites. - - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Other display formats",
    Media: "Mobile Swipecube",
    Format: "Mobile Swipecube",
    Type: "Display",
    Dimensions: "320x320",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "2-5 banners  //    In case of different landing pages per cube side, these and potential UTM need to be implemented in the creative code by the agency. See guidelines for clickTag and exit events in comment column. An additional JPG backup images must be ",
    "Max weight": "120 KB (total)",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec.",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Landing page per cube side and UTM is implemented by creative agency in different exit events  -   Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default”\n All mobile material should be coded in MRAID to ensure the material functions fully in the chosen apps/mobile sites. - - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Other display formats",
    Media: "Topbanner Double height - Programmatic only",
    Format: "Display Banners",
    Type: "Display",
    Dimensions: "930x360",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "HTML5, JPG, GIF   //   An additional JPG backup images must be provided per dimension outside of the zipped HTML5 file. Creatives should be named according to creative batch message",
    "Max weight": "120 KB ",
    "Approved files": "HTML5, JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "Max 15",
    Animation: "Max 30 sec. (3x10 sec loops)",
    Framerate: "24 FPS",
    Bitrate: "-",
    Streaming: "Stop video button, stop sound button",
    "Sound when streamning":
      "By mouse over min 1 sec. delay, stop by mouse out/click",
    "URL activation":
      "Standard clickTAG - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "Important: If delivered in HTML5, please provide backup banners and name them “Default” - In case of third party calls (especially video banners), the exact directory’s sub/root domain must be approved by Google. Adform, Adnami or Adtoox should be working alternatives. Direct contact and/or upload is handled by the creative agency. \n Full list under ‘list of certified provider domains’: https://support.google.com/displayvideo/answer/9009617?hl=en",
  },
  {
    Category: "Native",
    Media: "Plista",
    Format: "Plista Native Ads",
    Type: "Display",
    Dimensions: "1,91:1  Aspect ratio (minimum 1200x627px)",
    Safezones: "-",
    "Ad Copy": "Headline: max 50 characters",
    "What material is requested": "Image + text",
    "Max weight": "Under 100 KB is recommended - Max 350 KB",
    "Approved files": "JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Minimum one image + text is required. We recommend that you send more than one image and text, in order for us to optimize the campaign along the way. ",
  },
  {
    Category: "Native",
    Media: "Passendo",
    Format: "Passendo Native Ads",
    Type: "Display",
    Dimensions: "1,91:1  Aspect ratio (minimum 1200x627px)",
    Safezones: "-",
    "Ad Copy": "Headline: max 60 characters // Body max 100 characters",
    "What material is requested": "Image",
    "Max weight": "Under 100 KB is recommended - Max 350 KB",
    "Approved files": "JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important: For optimization Passendo requires minimum 3 different images in each format and minimum 3 text versions per campaign.\n The images may not be graphic, contain logo or other text. Passendo must approve all images and texts, so we recommend you send original images for approval before cropping.\n ",
  },
  {
    Category: "Native",
    Media: "Strossle",
    Format: "Strossle Native Ads",
    Type: "Display",
    Dimensions: "4:3 Aspect ratio image",
    Safezones: "-",
    "Ad Copy": "Headline: Max 60 characters // Body Max 90 characters",
    "What material is requested": "Image + text",
    "Max weight": "Under 100 KB is recommended - Max 2 MB",
    "Approved files": "JPEG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://www.strossle.com/da/strossle-native-advertising-ad-specs",
  },
  {
    Category: "Audio",
    Media: "Audio",
    Format: "Pre-, mid & postroll",
    Type: "Audio file",
    Dimensions: "Max 30 sec.; Companion Banner size: 301x301 JPEG",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Audio file",
    "Max weight": "500 Kb",
    "Approved files": "MP3",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "198 BPS",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "Sample Rate: 44,1 kbps; Companion Banner size: 301x301 JPEG",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Audio",
    Type: "Audio file",
    Dimensions: "15, 20 or 30 sec.",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Audio",
    "Max weight": "1 MB",
    "Approved files": "wav (16-bit 44.1 kHZ) mp3 (at least 192 kbps)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "https://spotifyforbrands.com/da-DK/ad-experiences/audio/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Audio",
    Type: "Companion Display",
    Dimensions: "640x640 pixels",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "200 KB",
    "Approved files": "JPG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "https://spotifyforbrands.com/da-DK/ad-experiences/audio/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Display",
    Type: "Standard Ad",
    Dimensions: "728x90",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Takeover",
    Type: "Overlay Desktop",
    Dimensions: "800x435",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Takeover",
    Type: "Leaderboard Companion",
    Dimensions: "728x90",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Takeover",
    Type: "Mobile Overlay",
    Dimensions: "630x920",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Homepage Takeover",
    Type: "Interactive Area",
    Dimensions: "800x235",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Homepage Takeover",
    Type: "Background Image",
    Dimensions: "1200x270",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "1 MB",
    "Approved files": "JPG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Branded Playlist",
    Type: "Cover art",
    Dimensions: "640x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "100KB",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Branded Playlist",
    Type: "Playlist Title",
    Dimensions: "Max. 40 characters",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Text",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Branded Playlist",
    Type: "Playlist Text",
    Dimensions: "Max. 200 characters",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Text",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Advertiser Page",
    Type: "Microsite",
    Dimensions: "1050x900",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Site",
    "Max weight": "-",
    "Approved files": "HTML5 (Video .webm, .ogv)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Video Takeover",
    Type: "Video (Spotify hosted)",
    Dimensions: "9:16 or 16:9",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "500 MB",
    "Approved files": "MOV, MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Video Takeover",
    Type: "End card (Spotify hosted)",
    Dimensions: "640x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "1 MB",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Sponsored Session",
    Type: "Video (Spotify hosted)",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "500 MB",
    "Approved files": "MOV, MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 15 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Spotify",
    Format: "Spotify Sponsored Session",
    Type: "End card (Spotify hosted)",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "1 MB",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Read more: https://www.spotifyforbrands.com/en-US/ad-experiences/",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Takeover",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Swipe",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Max. 8 banners",
    "Max weight": "65 KB pr. banner",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Scratch",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banners",
    "Max weight": "65 KB pr. banner",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "SLIDE 1: Der skal være mindst to slides. 1. slide skal kunne skrabes (ala – Find ud af hvor du kan få Starbucks-kaffe)\n SLIDE 2 – inkl knap med store-locator (“find din nærmeste Q8 her)\n SLIDE 3 to ? Evt. Produktoversigt",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Store Locator",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "65 KB pr. banner",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Provide a spreadsheet with the columns: street address* (max 1000 addresses), city*, latitude, longitude, company name, phone and URL.\n street address and city are mandatory and need to be available through Google Maps.",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Store locator (kolonne 1: “street adress”, kolonne 2: “city”)",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spreadsheat",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Interstial Porttrait + Landscape",
    Dimensions: "640x960",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Interstial Porttrait + Landscape",
    Dimensions: "960x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Display Mobile",
    Type: "Flipbook",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "One front image (either takeover or panorama XL) and then the flipbook, up to 6 pages",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video",
    Dimensions: "Ratio 16:9",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 120 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 fps.",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video start banner",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banners (1-3)",
    "Max weight": "65 KB pr. banner",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video end banner",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "65 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Streamview",
    Dimensions: "Ratio 1:1 or 16:9 or 2:1",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 120 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 fps.",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Streamview start banner (optional)",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "65 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Streamview end banner (optional)",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "65 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Streamview logo",
    Dimensions: "25x25",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Logo",
    "Max weight": "10 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Takeover Split",
    Dimensions: "Ratio 16:9",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 120 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 fps.",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Takeover Split start banner",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner (1-3)",
    "Max weight": "65 KB pr. banner",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Takeover Split end banner",
    Dimensions: "600x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "65 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Video",
    Dimensions: "Ratio 16:9",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 120 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 fps.",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Portrait start banner",
    Dimensions: "640x960",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Portrait end banner",
    Dimensions: "640x960",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Landscape start banner",
    Dimensions: "960x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Landscape end banner",
    Dimensions: "960x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Split Video",
    Dimensions: "Ratio 16:9",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 120 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 fps.",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Split Portrait start banner",
    Dimensions: "640x960",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Split Portrait end banner",
    Dimensions: "640x960",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Split Landscape start banner",
    Dimensions: "960x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Widespace",
    Format: "Widespace Video Mobile",
    Type: "Video Interstial Split Landscape end banner",
    Dimensions: "960x640",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Video",
    Type: "PlayAd Mobile Video Takeover",
    Dimensions: "320x320",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Video",
    Type: "PlayAd Mobile Video Splash",
    Dimensions: "320x480",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Video",
    Type: "PlayAd Mobile Video Panorama",
    Dimensions: "320x50",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Display",
    Type: "PlayAd Mobile DisplayTakeover",
    Dimensions: "320x320",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Display",
    Type: "PlayAd Mobile Display Splash",
    Dimensions: "320x480",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Display",
    Type: "PlayAd Mobile Display Panorama",
    Dimensions: "320x50",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Display",
    Type: "PlayAd Mobile Display Text",
    Dimensions: "40x40",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Logo",
    "Max weight": "-",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile Display",
    Type: "PlayAd Mobile Display Text",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Text",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile 3D Swipe",
    Type: "PlayAd Mobile Swipe Takeover",
    Dimensions: "320x320",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Mobile 3D Swipe",
    Type: "PlayAd Mobile Cube Takeover",
    Dimensions: "320x480",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Specific Video",
    Type: "PlayAd Specific Video",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 20 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Expander",
    Type: "PlayAd Engage Expander Video Loop",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "3-15 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Expander",
    Type: "Engage Expander Graphic Layer (Transparent background)",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "100 KB",
    "Approved files": "PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Expander",
    Type: "PlayAd Engage Expander Micro-site",
    Dimensions: 1100,
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Site",
    "Max weight": "-",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Expander",
    Type: "PlayAd Engage Expander Video",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Widescreen Specifications",
    Type: "PlayAd Engage Widescreen Video Loop",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "3-15 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Widescreen Specifications",
    Type: "PlayAd Engage Widescreen Graphic Layer (Transparent background)",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "100 KB",
    "Approved files": "PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Widescreen Specifications",
    Type: "PlayAd Engage Widescreen Micro-site",
    Dimensions: 1100,
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Site",
    "Max weight": "-",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "PlayAd",
    Format: "PlayAd Engage Widescreen Specifications",
    Type: "PlayAd Engage Widescreen Video",
    Dimensions: "720x405",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Adcolony",
    Format: "Adcolony Mobile",
    Type: "Video Spot",
    Dimensions: "16:9 (1920x1080)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Spot",
    "Max weight": "7 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "H.264",
    "Video length": "Max. 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "Adcolony",
    Format: "Adcolony Mobile",
    Type: "End Card",
    Dimensions:
      "1200x800 + logo/fonts etc. for the production – preferrably a PSD file + elements",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "50 KB",
    "Approved files":
      "Layered PSD, AI, EPS, GIF, PNG Sequences (animation), high-res JPG _x001F_les & MP3",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "400x400",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "930x180",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "300x250",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "728x90",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "160x600",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "728x200",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "Affiliate",
    Media: "Affiliate",
    Format: "Affiliate: Adservice",
    Type: "Affiliate Display Banner",
    Dimensions: "650x500",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF or JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "No max. characters, but approximately 250.\n The material must be send physically – not as tags",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept Display Advertising",
    Type: "Concept Add to calender",
    Dimensions: "320x320/160",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept Display Advertising",
    Type: "Concept Cupe",
    Dimensions: "160x300/600",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept Display Advertising",
    Type: "Concept Expand",
    Dimensions: "160x300/600",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept Display Advertising",
    Type: "Concept Store Locator",
    Dimensions: "320x320/160",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Concept Banner",
    Dimensions: "320x480",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5, GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Expand leaderboard",
    Dimensions: "930x180/expand 600",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Floor Ad",
    Dimensions: "100% width x 0/expand up to 50% height",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5, GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Interscroll (Mobile)",
    Dimensions: "736x736",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Main message position: centered and 60px from top, in case of navigation bar. Main message size (w x h): 290 x 416px.    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Mega banner",
    Dimensions: "930x600",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5, GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Overlay desktop",
    Dimensions: "960x600",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5, GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Overlay mobile",
    Dimensions: "320x320",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Banner",
    "Max weight": "100 KB",
    "Approved files": "HTML5, GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "Unlimited. Max. 1.1 MB in additional file size",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "Must be user-initiated",
    "URL activation": "-",
    Comment:
      "Important: If creatives are build as HTML5 it must be build according to Google’s specs. Click here for specs: https://support.google.com/campaignmanager/answer/3145300?hl=en    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Concept",
    Format: "Concept High Impact",
    Type: "Wallpaper (Desktop)",
    Dimensions: "2560x1440 + 930x180 // OBS SAFEZONES! Use cutout/grid 1020px",
    Safezones: "https://www.conceptnordic.com/specs",
    "Ad Copy": "-",
    "What material is requested": "Wallpaper",
    "Max weight": "200 KB + 100 KB",
    "Approved files": "GIF, JPEG or PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "In general on campaigns that run on more than two sites please use cutout at 1020 px.    //    See specs and wallpaper tester here: https://www.conceptnordic.com/specs",
  },
  {
    Category: "IO",
    Media: "Just Premium",
    Format: "Just Premium Ad",
    Type: "Ad",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "(PSD) with layers\n Remember to include - Font: OTF or TTF - Content: Copy & CTA (call to action) in different language(s) if needed - Logo: Preferably in vector format (SVG, EPS, PDF)",
    "Max weight": "-",
    "Approved files":
      "High Res images: JPG, PNG, TIFF - Video: Height resolution MP4 or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "IO",
    Media: "SeenThis",
    Format: "SeenThis Ad",
    Type: "Ad",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "(PSD) with layers\n Remember to include - Font: OTF or TTF - Content: Copy & CTA (call to action) in different language(s) if needed - Logo: Preferably in vector format (SVG, EPS, PDF)",
    "Max weight": "-",
    "Approved files":
      "High Res images: JPG, PNG, TIFF - Video: Height resolution MP4 or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "Twitch",
    Format: "WebTV",
    Type: "Twitch: SureStream – Ad Server Hosted Video",
    Dimensions: "1920x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "240 MB",
    "Approved files": "MP4",
    "Audio levels": "-9dB",
    "Video codec": "-",
    "Video length": "Up to 30s unskippable",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "Min. 2000kbps.",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Approved 3rd party Tracking Vendors: DCM, Innovid, Sizmek, Flashtalking - Supported 3rd Party Tracking Events: Impressions, Quartiles, Completes",
  },
  {
    Category: "Video",
    Media: "Twitch",
    Format: "WebTV",
    Type: "Twitch: SureStream – Third-Party Served (VAST)",
    Dimensions: "1920x1080 at 2000 kbps",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "Refer to previous video file specs. The source video file used to create a VAST must adhere to these requirements",
    "Max weight": "-",
    "Approved files":
      "VAST 3.0 only, VPAID is not accepted - Must NOT have geo, browser or any other targeting on the third party end.",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 FPS",
    Bitrate: "Min. 2000kbps.",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Approved 3rd party Tracking Vendors: DCM, Innovid, Sizmek, Flashtalking - Supported 3rd Party Tracking Events: Impressions, Quartiles, Completes - Required alphanumeric “id” attribute populated on the element in VAST XML - Important:\n – Third party redirecting or 4th party tags are not accepted.\n – Twitch does not allow the use of any unapproved 3rd party ad technologies.\n – Twitch requires all ads and 3rd party vendors to be SSL-compliant. All components of video and display ads including creative and tracking must be provided as and able to serve via SSL (HTTPS).",
  },
  {
    Category: "Video",
    Media: "Twitch",
    Format: "WebTV",
    Type: "Twitch: Cross Screen Video – Ad Server Hosted Video",
    Dimensions: "1920x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-9dB",
    "Video codec": "H.264 (MP4)",
    "Video length":
      "Up to 30s unskippable\n \n Extra charge for 60s, midroll only",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Min. 24 FPS, Max, 30 FPS",
    Bitrate: "Min. 1000kpbs",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Supported 3rd Party Tracking Vendors: Impressions,\n Clicks, Quartiles, Completes",
  },
  {
    Category: "Video",
    Media: "Twitch",
    Format: "WebTV",
    Type: "Twitch: SureStream – Third-Party Served (VAST)",
    Dimensions:
      "– 1920x1080 – 5,500 kbps\n – 1280x720 – 3,100 kbps\n – 854x480 – 1,200 kbps\n – 640x360 – 550 kbps\n – 284x160 – 180 kbpsExtra:\n – 1024x576 – 1,750 kbps\n – 888x500 – 1,316 kbps\n – 720x406 – 866 kbps\n – 480x270 – 384 kbps\n – 320x180 – 200 kbps",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "Refer to previous video file specs. The source video file used to create a VAST must adhere to these requirements.",
    "Max weight": "-",
    "Approved files":
      "Desktop: VAST 3.0, VPAID is not accepted\n Mobile App: VAST 3.0 - Must NOT have geo, browser or any other targeting on the third party end.",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "Max. 30 FPS",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important:\n – Third party redirecting or 4th party tags are not accepted.\n – Twitch does not allow the use of any unapproved 3rd party ad technologies.\n – Twitch requires all ads and 3rd party vendors to be SSL-compliant. All components of video and display ads including creative and tracking must be provided as and able to serve via SSL (HTTPS).",
  },
  {
    Category: "Video",
    Media: "X-Play WebTV",
    Format: "WebTV",
    Type: "Pre-, mid & postroll",
    Dimensions: "1920x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "100 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "Max 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "X-Play WebTV",
    Format: "WebTV",
    Type: "Inread",
    Dimensions: "1920x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "100 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "Max 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "X-Play WebTV",
    Format: "WebTV",
    Type: "Bumpers",
    Dimensions: "1920x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "100 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "6 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "Dplay WebTV",
    Format: "WebTV",
    Type: "Pre-, mid & postroll",
    Dimensions: "1920x1080 (16:9)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "50 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264/AVC",
    "Video length": "Max 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "NENT WebTV",
    Format: "WebTV",
    Type: "Pre-, mid & postroll",
    Dimensions: "1920x1080 (16:9)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "100 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "Max 90 sec. ",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "VAST may not contain VPAID media file ",
  },
  {
    Category: "Video",
    Media: "NENT WebTV",
    Format: "WebTV",
    Type: "Bumpers",
    Dimensions: "1920x1080 (16:9)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "100 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "6 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "TV2 WebTV",
    Format: "Pre-, mid & postroll",
    Type: "Pre-, mid & postroll",
    Dimensions: "1920x1080 (16:9)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "50 MB",
    "Approved files": "MP4",
    "Audio levels":
      "AAC\n R128 loudness: intergrated loudness (I) -23 LUFS ±1.0 LU",
    "Video codec": "H.264",
    "Video length": "Max 30 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS",
    Bitrate: 8000,
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "-",
  },
  {
    Category: "Video",
    Media: "TV2 Adressable TV",
    Format: "Adressable TV",
    Type: "Adressable TV",
    Dimensions: "Video – High definition 1080i/25 (16:9)",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "XDCAM HD 422 MXF OP1a (SMPTE 378M)",
    "Audio levels":
      "AAC R128 loudness: intergrated loudness (I) -23 LUFS ±0.5 LU. Max TP -3 dBTP. Max short-term loudness +5 LU (-18LUFS",
    "Video codec": "MPEG-2 422P@HL Long GOP",
    "Video length": "Max 90 sec.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "25 FPS (50i)",
    Bitrate: "50 Mb/s (CBR)",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://reklameaflevering.tv2.dk/help_files/Formater/TV2_Commercial_Specifications_2018v3_ENG.pdf    //    Important: The file must contain only the active video and audio content and thus may not start as black or still image or have another kind of pre- and/or post-roll. Duration is calculated from the file’s first frame to the last frame and must be an even number of seconds in accordance with the purchased commercial slot. A duration that falls short of, or exceeds the duration with one frame or more, will be rejected.\n TV 2 only accepts commercials that have a duration which is divisible by 5 seconds. This also applies to split-commercials. Split-commercials of more than 2 splits require prior agreement with TV 2.\n For further information: http://reklameaflevering.tv2.dk/help_files/Formater/TV2_Commercial_Specifications_2018v3_ENG.pdf",
  },
  {
    Category: "Video",
    Media: "Youtube",
    Format: "Youtube",
    Type: "Bumper/TrueView Ads",
    Dimensions: "Video, 16:9 + 300x60 companion banner",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "Link to the client’s YouTube-channel where the ad is uploaded",
    "Max weight": "1 GB + 50 kb",
    "Approved files": "AVI, ASF, Quicktime, Windows Media, MP4, or MPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Min. 6 sec. / Max 3 min.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important: If a companion ad isn’t provided, no other advertiser’s ad will appear next to your pre-roll. When comparing video impressions with companion impressions remember that companion banner ads are not served on all YouTube pages where in-stream ads are served.\n For further information:  https://support.google.com/displayspecs/answer/6055025?hl=da",
  },
  {
    Category: "Video",
    Media: "Youtube",
    Format: "Youtube",
    Type: "Bumper/TrueView Ads",
    Dimensions: "Video, 4:3 + 300x60 companion banner",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested":
      "Link to the client’s YouTube-channel where the ad is uploaded",
    "Max weight": "1 GB + 50 kb",
    "Approved files": "AVI, ASF, Quicktime, Windows Media, MP4, or MPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Min. 6 sec. / Max 3 min.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important: If a companion ad isn’t provided, no other advertiser’s ad will appear next to your pre-roll. When comparing video impressions with companion impressions remember that companion banner ads are not served on all YouTube pages where in-stream ads are served.\n For further information:  https://support.google.com/displayspecs/answer/6055025?hl=da",
  },
  {
    Category: "Video",
    Media: "Youtube",
    Format: "Youtube",
    Type: "Discovery Ads",
    Dimensions: "Video 16:9 ",
    Safezones: "-",
    "Ad Copy":
      "Headline: max 100 characters// Descripton: 2 lines of 35 characters each",
    "What material is requested":
      "Link to clien's Youtube channel/video + texts",
    "Max weight": "-",
    "Approved files":
      "                                                                                                                                                                                                                     ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://support.google.com/google-ads/answer/6227733?&ref_topic=3119118#",
  },
  {
    Category: "Video",
    Media: "Youtube",
    Format: "Youtube",
    Type: "Masthead",
    Dimensions: "Video 16:9 ",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Descripton: 50 characters // CTA text: 15 characters",
    "What material is requested":
      "Link to the client’s YouTube-channel where the ad is uploaded",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://support.google.com/displayspecs/answer/6245253",
  },
  {
    Category: "Video",
    Media: "Youtube",
    Format: "Youtube",
    Type: "Vertical Ads",
    Dimensions: "2:3 or 1:1",
    Safezones: "-",
    "Ad Copy": "CTA: 15 characters",
    "What material is requested":
      "Link to the client’s YouTube-channel where the ad is uploaded",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Important: Vertical videos may be cropped at the top and bottom of the video depending on screen sizes. Square videos will not. You should preview vertical assets in the YouTube app (as vertical organic video) to ensure critical messaging is visible. Avoid core messaging in the top 10% and bottom 20%. This may change as new devices are released.",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Desktop",
    Type: "Topscroll HTML (Desktop)",
    Dimensions:
      "1920x1080 - Viewport 100% Width x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-desktop-html",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-desktop-html      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Desktop",
    Type: "Topscroll Image (Desktop)",
    Dimensions:
      "2560x1440 - Viewport 100% Width + 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-desktop-image",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG or GIF",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-desktop-image      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Desktop",
    Type: "Topscroll Video (Desktop",
    Dimensions:
      "16:9 (1920x1080) - Viewport 100%  Width x 80% Height  // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-desktop-video",
    "Ad Copy": "-",
    "What material is requested":
      "Compressed and weight optimized video MP4 file",
    "Max weight": "3,5 MB",
    "Approved files": "MP4",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-desktop-video      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Desktop",
    Type: "Midscroll HTML (Desktop)",
    Dimensions: "1920x1080 - Viewport 100% x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-desktop-fullscreen-html",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-desktop-fullscreen-html      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Desktop",
    Type: "Midscroll Image (Desktop)",
    Dimensions:
      "2560x1440 - Viewport 100% Width + 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-desktop-fullscreen-image",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG or GIF",
    "Max weight": "500KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-desktop-fullscreen-image      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Desktop",
    Type: "Midscroll Video (Desktop)",
    Dimensions:
      "16:9 (1920x1080) - Viewport 100%  Width x 80% Height  // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-desktop-fullscreen-video",
    "Ad Copy": "-",
    "What material is requested":
      "Compressed and weight optimized video MP4 file",
    "Max weight": "3,5 MB",
    "Approved files": "MP4",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-desktop-fullscreen-video      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Desktop",
    Type: "Midscroll Double Height HTML (Desktop)",
    Dimensions:
      "1920x1080 - Viewport 100% Width x 100% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-desktop-double-height",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-desktop-double-height      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1440 + 930x180 // OBS SAFEZONES! Use cutout/grid 1020px",
    Safezones: "https://www.adnami.io/specs/image-skin",
    "Ad Copy": "-",
    "What material is requested":
      "JPG/PNG wallpaper (cutout/grid 1020) + Topbanner either JPG/PNG/GIF or HTML5 (if HTML, also a static 930x180 is needed). For TV2 a second Wide format wallpaper with cutout 1260px is optional)",
    "Max weight": "500 KB + 120 KB",
    "Approved files": "JPG, PNG, HTML5",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "https://www.adnami.io/specs/image-skin      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Fluid skin HTML (Desktop)",
    Dimensions:
      "800x1440 left/right panels + 1000x180 topbanner // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/fluid-skin",
    "Ad Copy": "-",
    "What material is requested":
      "3x Responsive and polite load optimized HTML5 filas as .zip or 3 third party tags",
    "Max weight": "3,5 MB",
    "Approved files": "HTML5",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/fluid-skin      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Fluid skin Video (Desktop)",
    Dimensions:
      "800x1440 left/right panels + 1000x180 topbanner // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/fluid-skin-image-video",
    "Ad Copy": "-",
    "What material is requested":
      "3x Responsive and polite load optimized assets as HTML5HTML5 filas as .zip or 3 third party tags",
    "Max weight": "3,5 MB",
    "Approved files": "MP4 + JPG/PNG",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/fluid-skin-image-video      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Mobile",
    Type: "Topscroll HTML (Mobile)",
    Dimensions:
      "1080x1920 - Viewport 100% Width x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-mobile-html",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-mobile-html      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Mobile",
    Type: "Topscroll image (Mobile)",
    Dimensions: "1080x1920 - Viewport 100% Width x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-mobile-image",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG or GIF",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-mobile-image      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Topscroll Mobile",
    Type: "Topscroll Video (Mobile)",
    Dimensions:
      "9:16 (1080x1920) - Viewport 100% Width x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/topscroll-mobile-video",
    "Ad Copy": "-",
    "What material is requested":
      "Compressed and weight optimized video MP4 file",
    "Max weight": "3,5 MB",
    "Approved files": "MP4",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/topscroll-mobile-video      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Mobile",
    Type: "Midscroll HTML (Mobile)",
    Dimensions: "1080x1920 - Viewport 100% x 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-mobile-html",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-mobile-html      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Mobile",
    Type: "Midscroll Image (Mobile)",
    Dimensions: "1080x1920 - Viewport 100% Width + 80% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-mobile-image",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG or GIF",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-mobile-image      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Mobile",
    Type: "Midscroll Video (Mobile)",
    Dimensions:
      "9:16 (1080x1920) - Viewport 100%  Width x 80% Height  // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/midscroll-mobile-video",
    "Ad Copy": "-",
    "What material is requested":
      "Compressed and weight optimized video MP4 file",
    "Max weight": "3,5 MB",
    "Approved files": "MP4",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/midscroll-mobile-video      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "Adnami",
    Media: "Adnami",
    Format: "Midscroll Mobile",
    Type: "Midscroll Double Height HTML (Mobile)",
    Dimensions:
      "1080x1920 - Viewport 100% Width x 100% Height // OBS SAFEZONES!",
    Safezones: "https://www.adnami.io/specs/double-height-midscroll-mobile",
    "Ad Copy": "-",
    "What material is requested":
      "Responsive HTML5 as .zip file or 3rd party tag. Minified content and polite load optimized. \n",
    "Max weight": "3,5 MB",
    "Approved files": "Zipped HTML5, 3rd party script",
    "Audio levels": "User-initated",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30sec",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://www.adnami.io/specs/double-height-midscroll-mobile      *CREATIVE HELP: support@adnami.io",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1140 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960px // https://adops.dk/wp-content/uploads/2020/02/JP_wallpaper_template_en.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "500 KB (Wallpaper) + 120 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Topscroll Desktop",
    Type: "Topscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/06/Desktop_topscroll_POLEBJP_v3_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Topscroll Tablet",
    Type: "Topscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_tablet_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Topscroll Mobil",
    Type: "Topscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_mobile_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/04/JP_Interscroll_template_desktop_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/JP_Interscroll_template_tablet_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Jyllands-Posten",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/JP_Interscroll_template_mobile_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Finans",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1140 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960px // https://adops.dk/wp-content/uploads/2020/02/Finans_wallpaper_template_en.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "500 KB (Wallpaper) + 120 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Finans",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/04/JP_Interscroll_template_desktop_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Finans",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/JP_Interscroll_template_tablet_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Finans",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/JP_Interscroll_template_mobile_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "TV2",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions:
      "2560x1440 x 2 (Narrow  Wide) + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 1000px  + 1260px // PSD template download: https://tv2media.dk/media/7947/20211006_tv2_dd_wallpaper_template-1.zip",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 100 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "30 sec, max 3 loops",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "mouse over only",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment: "https://tv2media.dk/tv2dk/produkter-specifikationer/",
  },
  {
    Category: "IO",
    Media: "TV2",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "PSD template download: https://gfx.tv2.dk/images/netsalg/banners/specs/TV2_desktop_takover_template.zip",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "350 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://tv2media.dk/tv2dk/produkter-specifikationer/",
  },
  {
    Category: "IO",
    Media: "TV2",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "PSD template download: https://gfx.tv2.dk/images/netsalg/banners/specs/TV2_tablet_takeover_template.zip",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://tv2media.dk/tv2dk/produkter-specifikationer/",
  },
  {
    Category: "IO",
    Media: "TV2",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "PSD template download: https://gfx.tv2.dk/images/netsalg/banners/specs/TV2_mobile_takeover_template.zip ",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment: "https://tv2media.dk/tv2dk/produkter-specifikationer/",
  },
  {
    Category: "IO",
    Media: "DBA",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop) ",
    Dimensions: "2560x1080 + 930x180 Topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960x900   //   https://media-banner.dbastatic.dk/GODKENDT/DBA_specs_wall.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 60 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment: "https://media-banner.dbastatic.dk/GODKENDT/DBA_specs_wall.jpg",
  },
  {
    Category: "IO",
    Media: "Bilbasen",
    Format: "Wallpaper SRP Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1080 + 930x180 Topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960x900   //    https://media-banner.dbastatic.dk/GODKENDT/Bilbasen_specs_SRP_wall.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 60 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "https://media-banner.dbastatic.dk/GODKENDT/Bilbasen_specs_SRP_wall.jpg",
  },
  {
    Category: "IO",
    Media: "Bilbasen",
    Format: "Wallpaper VIP Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1080 + 930x180 Topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 1024x900   //   https://media-banner.dbastatic.dk/GODKENDT/Bilbasen_specs_VIP_wall.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 60 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "https://media-banner.dbastatic.dk/GODKENDT/Bilbasen_specs_VIP_wall.jpg",
  },
  {
    Category: "IO",
    Media: "Benjamin Media",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1200 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960px // https://benjamin.dk/wp-content/uploads/2021/02/19022021_Generel-adveritising-specifications.pdf",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 150 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "https://benjamin.dk/wp-content/uploads/2021/02/19022021_Generel-adveritising-specifications.pdf",
  },
  {
    Category: "IO",
    Media: "Benjamin Media",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "930x960   //   OBS SAFEZONES",
    Safezones: "180px top, 380px bottom",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB ",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "Click tracking",
    Comment:
      "https://benjamin.dk/wp-content/uploads/2021/02/19022021_Generel-adveritising-specifications.pdf",
  },
  {
    Category: "IO",
    Media: "Idenyt",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1200 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 1020px // https://benjamin.dk/wp-content/uploads/2021/02/19022021_Generel-adveritising-specifications.pdf",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (Wallpaper) + 150 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation":
      "Standard clickTAG for Topbanner if HTML (remember sending separately a .jpg in same size for default banner) - If adserver is Campaign Manager then use thsi guide: https://support.google.com/campaignmanager/answer/3145300?hl=en#zip&zippy=%2Cclick-tag-guidelines%2Csample-click-tag-insertion%2Cuse-google-web-designer-for-exit-events",
    Comment:
      "https://benjamin.dk/wp-content/uploads/2021/02/19022021_Generel-adveritising-specifications.pdf",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1440 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones: "Grid 1000px // https://www.bt.dk/?displaywallpaper",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "200 KB (wallpaper) + 120 KB (topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "9sec, max 5 loops",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "mouse over only",
    "URL activation": "click tracker",
    Comment:
      "Demo link: https://www.bt.dk/?displaywallpaper   //   https://annoncering.bt.dk/dk/products/btdk/bt-nyhedsbreve-1-1-1-1-1-1-1-1/",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Desktop",
    Type: "Topscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "200px top, 300px bund, 200px sider  //   https://www.e-pages.dk/berlingskemedia/1537/",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG, GIF",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscroll",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Desktop",
    Type: "Topscroll Video (Desktop",
    Dimensions: "16:9 (1920x1080)   //   OBS SAFEZONES",
    Safezones:
      "200px top, 300px bund, 200px sider  //  https://www.e-pages.dk/berlingskemedia/1537/",
    "Ad Copy": "-",
    "What material is requested": "MP4",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "muted",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscrollvideo",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Tablet",
    Type: "Topscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "100px top, 200px bund, 150px sider  //   https://www.e-pages.dk/berlingskemedia/1536/",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG, GIF",
    "Max weight": "300 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscroll",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Tablet",
    Type: "Topscroll Video (Tablet)",
    Dimensions: "16:09 (1024x1024)   //   OBS SAFEZONES",
    Safezones:
      "100px top, 200px bund, 150px sider  //   https://www.e-pages.dk/berlingskemedia/1536/",
    "Ad Copy": "-",
    "What material is requested": "MP4",
    "Max weight": "10 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "muted",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscrollvideo",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Mobil",
    Type: "Topscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "150px bund, 200px sider  //  https://www.e-pages.dk/berlingskemedia/1535/",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG, GIF",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscroll",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Topscroll Mobil",
    Type: "Topscroll Video (Mobil)",
    Dimensions: "9:16   //   OBS SAFEZONES",
    Safezones:
      "150px bund, 200px sider  //  https://www.e-pages.dk/berlingskemedia/1535/",
    "Ad Copy": "-",
    "What material is requested": "MP4",
    "Max weight": "5 MB",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "muted",
    "URL activation": "click tracker",
    Comment: "Demo link: https://www.bt.dk/?displaytopscrollvideo",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "140px top, 230px bund, 200px sider // https://www.e-pages.dk/berlingskemedia/1534/",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG, GIF",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment:
      "Demo link: https://www.bt.dk/?displayinterscroll    //   https://annoncering.bt.dk/dk/products/btdk/bt-nyhedsbreve-1-1-1-1/",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "140px top, 140px bund, 200px sider  //  https://www.e-pages.dk/berlingskemedia/1538/",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG, GIF",
    "Max weight": "300 KB",
    "Approved files": "JPG, PNG, GIF",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment:
      "Demo link: https://www.bt.dk/?displayinterscroll    //   https://annoncering.bt.dk/dk/products/btdk/bt-nyhedsbreve-1-1-1-1-1-1/",
  },
  {
    Category: "IO",
    Media: "BT",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones: "170px top, 140px bund, 320px sider",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment:
      "Demo link: https://www.bt.dk/?displayinterscroll   //   https://annoncering.bt.dk/dk/products/btdk/bt-nyhedsbreve-1-1-1-1-1/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1140 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 930px // https://adops.dk/wp-content/uploads/2020/02/EB_wallpaper_template_en.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "500 KB (Wallpaper) + 120 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Topscroll Desktop",
    Type: "Topscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/06/Desktop_topscroll_POLEBJP_v3_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Topscroll Tablet",
    Type: "Topscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_tablet_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Topscroll Mobil",
    Type: "Topscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_mobile_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/04/EB_Interscroll_template_desktop_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/01/EB_Interscroll_template_tablet_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Ekstra Bladet",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/01/EB_Interscroll_template_mobil_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Wallpaper Desktop",
    Type: "Wallpaper Image (Desktop)",
    Dimensions: "2560x1140 + 930x180 topbanner   //   OBS SAFEZONES",
    Safezones:
      "Grid 960px // https://adops.dk/wp-content/uploads/2020/02/POL_wallpaper_template_en.jpg",
    "Ad Copy": "-",
    "What material is requested":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Max weight": "500 KB (Wallpaper) + 120 KB (Topbanner)",
    "Approved files":
      "JPG (wallpaper) + HTML/JPG (topbanner) - for Campaign Manager always a 930x180 fallback is needed additionally to a HTML topbanner)",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Topscroll Desktop",
    Type: "Topscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/06/Desktop_topscroll_POLEBJP_v3_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Topscroll Tablet",
    Type: "Topscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_tablet_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Topscroll Mobil",
    Type: "Topscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/02/POLEBJP_topscroll_template_mobile_EN.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Interscroll Desktop",
    Type: "Interscroll Image (Desktop)",
    Dimensions: "2560x1440   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/04/POL_Interscroll_template_desktop_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "500 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Interscroll Tablet",
    Type: "Interscroll Image (Tablet)",
    Dimensions: "1024x1024   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/01/POL_Interscroll_template_tablet_en-1.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "IO",
    Media: "Politiken",
    Format: "Interscroll Mobil",
    Type: "Interscroll Image (Mobil)",
    Dimensions: "736x736   //   OBS SAFEZONES",
    Safezones:
      "https://adops.dk/wp-content/uploads/2022/01/POL_Interscroll_template_mobile_en.jpg",
    "Ad Copy": "-",
    "What material is requested": "JPG, PNG",
    "Max weight": "200 KB",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "click tracker",
    Comment: "https://adops.dk/en/products/",
  },
  {
    Category: "Social",
    Media: "LinkedIn",
    Format: "Single Image Ads ",
    Type: "Horizontal/Landscape (Desktop, Mobile)",
    Dimensions: "1.91:1 aspect ratio - Between 640x360 to 7680x4320 ",
    Safezones: "-",
    "Ad Copy":
      "Ad name (optional):\xa0255 characters // Headline: 70 characters // Introductory text:  150 characters ",
    "What material is requested": "JPG or PNG ",
    "Max weight": "5 MB",
    "Approved files": "JPG or PNG ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn",
    Format: "Single Image Ads ",
    Type: "Square (Desktop, Mobile)",
    Dimensions: "1:1 aspect ratio - Between 360x360 to 4320x4320",
    Safezones: "-",
    "Ad Copy":
      "Ad name (optional):\xa0255 characters // Headline: 70 characters // Introductory text:  150 characters ",
    "What material is requested": "JPG or PNG ",
    "Max weight": "5 MB",
    "Approved files": "JPG or PNG ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn",
    Format: "Single Image Ads ",
    Type: "Vertical (Mobile)",
    Dimensions: "1:1.91  aspect ratio - Between 360x640 to 1254x2400",
    Safezones: "-",
    "Ad Copy":
      "Ad name (optional):\xa0255 characters // Headline: 70 characters // Introductory text:  150 characters ",
    "What material is requested": "JPG or PNG ",
    "Max weight": "5 MB",
    "Approved files": "JPG or PNG ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn ",
    Format: "Video Ads ",
    Type: "Vertical video",
    Dimensions:
      "9:16 or 4:5 aspect ratios - between 360x640 and 1080x1920 or 360x640 to 2340x4320 respectively",
    Safezones: "-",
    "Ad Copy":
      "Ad name\xa0(optional):\xa0255 characters, Headline: 70 characters, Introductory text: 150 characters",
    "What material is requested": "MP4",
    "Max weight": "Video File Size:\xa075 KB (min) - 200 MB (max)",
    "Approved files": "MP4, ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "3-30 min. ",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn ",
    Format: "Video Ads ",
    Type: "Landscape video",
    Dimensions: "16:9 aspect ratio -  Between 640x360 and 1920x1080 ",
    Safezones: "-",
    "Ad Copy":
      "Ad name\xa0(optional):\xa0255 characters, Headline: 70 characters, Introductory text: 150 characters",
    "What material is requested": "MP4",
    "Max weight": "Video File Size:\xa075 KB (min) - 200 MB (max)",
    "Approved files": "MP4, ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "3-30 min. ",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn ",
    Format: "Video Ads ",
    Type: "Square video",
    Dimensions: "1:1 aspect ratio - Between 360x360 and 1920x1920",
    Safezones: "-",
    "Ad Copy":
      "Ad name\xa0(optional):\xa0255 characters, Headline: 70 characters, Introductory text: 150 characters",
    "What material is requested": "MP4",
    "Max weight": "Video File Size:\xa075 KB (min) - 200 MB (max)",
    "Approved files": "MP4, ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "3-30 min. ",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn ",
    Format: "Carousel Ads ",
    Type: "Carousel Ads image",
    Dimensions: "1:1 aspect ratio - Min. 1080x1080 ",
    Safezones: "-",
    "Ad Copy":
      "Ad name\xa0(optional):\xa0255 characters // Card headline: 45 characters // Introductory text: 255 characters",
    "What material is requested": "JPG or PNG ",
    "Max weight": "10 MB",
    "Approved files": "JPG or PNG ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "LinkedIn",
    Format: "Event Ads ",
    Type: "Event Ads image",
    Dimensions: "4:1 aspect ratio (image will be pulled from the Event page)",
    Safezones: "-",
    "Ad Copy":
      "Event name (optional):\xa0255 characters // Introductory text :  600 characters ",
    "What material is requested": "JPG or PNG ",
    "Max weight": "5 MB",
    "Approved files": "JPG or PNG ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More information: https://business.linkedin.com/marketing-solutions/success/ads-guide",
  },
  {
    Category: "Social",
    Media: "Pinterest",
    Format: "Pinterest Standard Pin",
    Type: "Standard Pin",
    Dimensions: "2:3 aspect ratio - minimum 1000x1500",
    Safezones: "-",
    "Ad Copy":
      "Title: Up to 100 characters. If you don’t fill in a title, people will see your Pin description instead.\n Description: Up to 500 characters. The first 50–60 characters are most likely to show up in people’s feeds, so put your most important info first. + These specs apply to both organic Pins and Ads.",
    "What material is requested": "Image",
    "Max weight": "32 MB",
    "Approved files": ".png or .jpg",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://help.pinterest.com/en/business/article/pinterest-product-specs",
  },
  {
    Category: "Social",
    Media: "Pinterest",
    Format: "Pinterest Video at standard width",
    Type: "Video at standard width",
    Dimensions: "2:3 aspeect ratio",
    Safezones: "-",
    "Ad Copy":
      "Title: Up to 100 characters. If you don’t fill in a title, people will see your Pin description instead.\n Description: Up to 500 characters. The first 50–60 characters are most likely to show up in people’s feeds, so put your most important info first. + These specs apply to both organic video and Promoted Video.",
    "What material is requested": "Video",
    "Max weight": "Up to 2 GB",
    "Approved files": ".mp4, .mov or .m4v",
    "Audio levels": "-",
    "Video codec": "H.264 of H.265",
    "Video length": "Minimum 4 seconds, maximum 15 minutes",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://help.pinterest.com/en/business/article/pinterest-product-specs",
  },
  {
    Category: "Social",
    Media: "Pinterest",
    Format: "Pinterest Video at max width",
    Type: "Promoted Video at max width",
    Dimensions: "1:1 Square or 16:9 widescreen aspect ratio",
    Safezones: "-",
    "Ad Copy":
      "Title: Up to 100 characters. If you don’t fill in a title, people will see your Pin description instead.\n Description: Up to 500 characters. The first 50–60 characters are most likely to show up in people’s feeds, so put your most important info first.",
    "What material is requested": "Video",
    "Max weight": "Up to 2 GB",
    "Approved files": ".mp4, .mov or .m4v",
    "Audio levels": "-",
    "Video codec": "H.264 of H.265",
    "Video length": "Minimum 4 seconds, maximum 15 minutes",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://help.pinterest.com/en/business/article/pinterest-product-specs",
  },
  {
    Category: "Social",
    Media: "Pinterest",
    Format: "Pinterest Carousels",
    Type: "Promoted Carousels",
    Dimensions: "1:1 or 2:3 aspect ratio",
    Safezones: "-",
    "Ad Copy":
      "Title: Up to 100 characters. The first 30–35 characters will show in people’s feeds.\n Description: Up to 500 characters. The first 50–60 characters are most important.",
    "What material is requested": "2-5 images per Carousel",
    "Max weight": "32 MB per image",
    "Approved files": ".png or .jpg",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://help.pinterest.com/en/business/article/pinterest-product-specs",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Snap Ads",
    Type: "Skippable video/image format (9:16 ratio)",
    Dimensions: "1080x1920 (9:16 ratio)",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/top-snap-specs?language=en_US",
    "What material is requested": "Video / Image",
    "Max weight": "-",
    "Approved files": "Video: MP4\nImage: JPN, PNG, PSD",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/top-snap-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Story Ads",
    Type: "Skippable video/image format  (9:16 ratio)",
    Dimensions: "VIdeo/image: 1080x1920\nTile: 360x600",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/story-ad-specs?language=en_US",
    "What material is requested": "Video / Image",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/story-ad-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Collection Ads",
    Type: "Skippable video/image format with 3-4 thumbnails in the bottom of the ad (9:16 ratio)",
    Dimensions: "1080x1920 (9:16 ratio)",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/collection-ad-specs?language=en_US",
    "What material is requested": "Video / Image",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/collection-ad-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Dynamic Ads",
    Type: "Dynamic static ad based on a product feed",
    Dimensions: "1080x1920 (9:16 ratio)",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/pixel-dynamic-best?language=en_US",
    "What material is requested": "Video / Image",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/pixel-dynamic-best?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Commercials",
    Type: "Non-skippable (first 6 seconds) video - mid roll",
    Dimensions: "1090x1920 (9:16 ratio)",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/commercial-specs?language=en_US",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/commercial-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Lens",
    Type: "Augmented Reality (AR) Lens",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/lens-specs?language=en_US",
    "What material is requested": "AR Lens",
    "Max weight": "-",
    "Approved files":
      "PNG asset with transparent background, max file size 300KB (click here for full specs)",
    "Audio levels": "-16 LUFS",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/lens-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat First Lens",
    Type: "AR Takeover: the first spot in the Lens carousel for 24 hours",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/first-lens-guide?language=en_US",
    "What material is requested": "AR Lens",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/first-lens-guide?language=en_US",
  },
  {
    Category: "Social",
    Media: "Snapchat",
    Format: "Snapchat Filter",
    Type: "Static overlay",
    Dimensions: "1080x2340 (min 50% must be transparent)",
    Safezones: "-",
    "Ad Copy":
      "https://businesshelp.snapchat.com/s/article/geofilter-specs?language=en_US",
    "What material is requested": "Static overlay",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://businesshelp.snapchat.com/s/article/geofilter-specs?language=en_US",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Video",
    Type: "In Feed Ad",
    Dimensions:
      "Vertical (highly recommended): 9:16 (≥ 540*960px),\n Landscape: 16:9 (≥ 960x540px) or\n Square: 1:1 ( ≥640x640px)",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 100 characters ",
    "What material is requested": "Video",
    "Max weight": "500 MB",
    "Approved files": "MP4, MPEG, 3GP, AVI or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "5-60s, recommend 9-15s.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "≥516 kbps\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10002742",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Organic Video",
    Type: "Spark Ad",
    Dimensions: "Vertical only: 9:16",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 150 characters ",
    "What material is requested": "Uploaded Video on the TikTok app",
    "Max weight": "-",
    "Approved files": "Uploaded videos .mp4 or .mov ",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Up to 3min, recommend 21-34s.\xa0",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate:
      "Aspect ratio of uploaded videos is normally less than 2.2 (eg. 16:9=16/9=1.7778), no other restrictions on resolution, bitrate, file size.\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10002742",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Video",
    Type: "Lead Generation Ad",
    Dimensions:
      "Vertical (highly recommended): 9:16 (≥ 540*960px),\n Landscape: 16:9 (≥ 960x540px) or\n Square: 1:1 ( ≥640x640px)",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 100 characters ",
    "What material is requested": "Video",
    "Max weight": "500 MB",
    "Approved files": "MP4, MPEG, 3GP, AVI or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "5-60s, recommend 9-15s.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "≥516 kbps\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10001625",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Video",
    Type: "Collection Ad",
    Dimensions:
      "Vertical (highly recommended): 9:16 (≥ 540*960px),\n Landscape: 16:9 (≥ 960x540px) or\n Square: 1:1 ( ≥640x640px)",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 100 characters ",
    "What material is requested": "Video",
    "Max weight": "500 MB",
    "Approved files": "MP4, MPEG, 3GP, AVI or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "5-60s, recommend 9-15s.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "≥516 kbps\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10001345",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Video",
    Type: "Instant Page",
    Dimensions:
      "Vertical (highly recommended): 9:16 (≥ 540*960px),\n Landscape: 16:9 (≥ 960x540px) or\n Square: 1:1 ( ≥640x640px)",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 100 characters ",
    "What material is requested": "Video",
    "Max weight": "500 MB",
    "Approved files": "MP4, MPEG, 3GP, AVI or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "5-60s, recommend 9-15s.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "≥516 kbps\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "More info: https://ads.tiktok.com/help/article?aid=10007479&q=Instant%20page",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "Add-Ons",
    Type: "Interactive Add Ons",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "-",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10002742",
  },
  {
    Category: "Social",
    Media: "TikTok",
    Format: "TikTok Top View",
    Type: "Top View",
    Dimensions: "9:16 (≥ 540*960px)",
    Safezones: "-",
    "Ad Copy": "Display name: 20 characters // Ad caption: 100 characters ",
    "What material is requested": "Video",
    "Max weight": "500 MB",
    "Approved files": "MP4, MPEG, 3GP, AVI or MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "5-60s, recommend 9-15s.",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "≥2,500kbps\xa0",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment: "More info: https://ads.tiktok.com/help/article?aid=10002742",
  },
  {
    Category: "Social",
    Media: "Twitter",
    Format: "Twitter Website Card",
    Type: "Website Image Card",
    Dimensions: "1200x1200 / 1920x1080",
    Safezones: "-",
    "Ad Copy":
      "Card text/Tweet Copy: 257 characters // Website Title Length: 70 characters",
    "What material is requested": "Image + text",
    "Max weight": "5 MB",
    "Approved files": "PNG/JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://business.twitter.com/en/help/campaign-setup/creative-ad-specifications.html",
  },
  {
    Category: "Social",
    Media: "Twitter",
    Format: "Twitter Website Card",
    Type: "Website Video Card",
    Dimensions: "1200x1200 / 1920x1080",
    Safezones: "-",
    "Ad Copy":
      "Card text/Tweet Copy: 257 characters // Website Title Length: 70 characters",
    "What material is requested": "video + text",
    "Max weight": "1 GB but under 30 MB is recommended",
    "Approved files": "MP4/MOV",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 140 sec. For videos up to 10 minutes contact Httpool",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "29.97-60 FPS",
    Bitrate: "6000-1000k for 1080p, 5000-8000k for 720p",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://business.twitter.com/en/help/campaign-setup/creative-ad-specifications.html",
  },
  {
    Category: "Social",
    Media: "Twitter",
    Format: "Twitter Carousel Ad",
    Type: "Carousel Ad",
    Dimensions: "800x800 / 800x418",
    Safezones: "-",
    "Ad Copy":
      "Card text/Tweet Copy: 257 characters // Website Title Length: 70 characters",
    "What material is requested": "Videos/Images + text",
    "Max weight": "Video recommended <30mb, max 1gb. Image: Max. 5mb",
    "Approved files": "MP4/MOV/PNG/JPEG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "29.97-60 FPS",
    Bitrate: "6000-1000k for 1080p, 5000-8000k for 720p",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://business.twitter.com/en/help/campaign-setup/creative-ad-specifications.html",
  },
  {
    Category: "Social",
    Media: "Twitter",
    Format: "Twitter Follower Ad",
    Type: "Text ad",
    Dimensions: "-",
    Safezones: "-",
    "Ad Copy":
      "Tweet copy: 280 characters. (Note: each link used reduces character count by 23 characters, electing 257 characters for Twitter copy.) Ensure there is actionable copy for this ad product.",
    "What material is requested": "text",
    "Max weight": "-",
    "Approved files": "-",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "https://business.twitter.com/en/help/campaign-setup/creative-ad-specifications.html",
  },
  {
    Category: "Social",
    Media: "Facebook",
    Format: "Facebook Photo Ad Square",
    Type: "Facebook Photo Ad Square (ratio: 1:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Link description: 30 characters // Primary text: 90 characters",
    "What material is requested": "Image + text",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Facebook",
    Format: "Facebook Video Ad",
    Type: "Facebook Video Ad Square (ratio: 4:5)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Link description: 30 characters // Primary text: 90 characters",
    "What material is requested": "Video + text",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 15 sec",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Facebook",
    Format: "Facebook Carousel Photo Ad",
    Type: "Facebook Carousel Photo Ad (ratio: 1:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Link description: 30 characters // Primary text: 90 characters",
    "What material is requested": "Image – Up to 10 cards allowed + text",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Image must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Facebook",
    Format: "Facebook Stories Photo Ad",
    Type: "Facebook Stories Photo Ad Vertical (ratio: 9:16)",
    Dimensions: "1080x1920",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Image must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Facebook",
    Format: "Facebook Stories Video Ad",
    Type: "Facebook Stories Video Ad Vertical (ratio: 9:16)",
    Dimensions: "1080x1920",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 15 sec",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Thumbnail must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Photo Ad",
    Type: "Instagram Photo Ad Square (ratio: 1:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Video Ad",
    Type: "Instagram Video Square (Ratio: 1:1 or 1,9:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Carousel Photo Ad",
    Type: "Instagram Carousel Photo Ad Square (ratio 1:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Link description: 30 characters // Primary text: 90 characters",
    "What material is requested": "Image – Up to 10 cards allowed + text",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Carousel Video Ad",
    Type: "Instagram Carousel Video Ad Square (ratio 1:1)",
    Dimensions: "1080x1080",
    Safezones: "-",
    "Ad Copy":
      "Headline: 25 characters // Link description: 30 characters // Primary text: 90 characters",
    "What material is requested": "Video + text",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 15 sec",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Image must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Stories Photo Ad",
    Type: "Instagram Stories Photo Ad Vertical (ratio 9.16)",
    Dimensions: "1080x1920",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Image",
    "Max weight": "-",
    "Approved files": "JPG, PNG",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "-",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Thumbnail must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
  {
    Category: "Social",
    Media: "Instagram",
    Format: "Instagram Stories Video Ad",
    Type: "Instagram Carousel Video Ad Vertical (ratio 9:16)",
    Dimensions: "1080x1920",
    Safezones: "-",
    "Ad Copy": "-",
    "What material is requested": "Video",
    "Max weight": "-",
    "Approved files": "MP4",
    "Audio levels": "-",
    "Video codec": "-",
    "Video length": "Max. 15 sec",
    "HTML5 file request": "-",
    Animation: "-",
    Framerate: "-",
    Bitrate: "-",
    Streaming: "-",
    "Sound when streamning": "-",
    "URL activation": "-",
    Comment:
      "Image must not contain more than 20% text // For further information: https://www.facebook.com/business/ads-guide/",
  },
];
