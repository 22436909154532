export const columns = [
  {
    title: "Category",
    field: "Category",
    show: true,
    filter: "text",
  },
  {
    title: "Media",
    field: "Media",
    show: true,
    filter: "text",
  },
  {
    title: "Format",
    field: "Format",
    show: true,
    filter: "text",
  },
  {
    title: "Type",
    field: "Type",
    show: true,
    filter: "text",
  },
  {
    title: "Dimensions",
    field: "Dimensions",
    show: true,
    filter: "text",
  },
  {
    title: "Safezones",
    field: "Safezones",
    show: false,
    filter: "text",
  },
  {
    title: "Ad Copy",
    field: "Ad Copy",
    show: false,
    filter: "text",
  },
  {
    title: "What material is requested",
    field: "What material is requested",
    show: false,
    filter: "text",
  },
  {
    title: "Max weight",
    field: "Max weight",
    show: true,
    filter: "text",
  },
  {
    title: "Approved files",
    field: "Approved files",
    show: false,
    filter: "text",
  },

  { field: "Audio levels", filter: "text", show: false, title: "Audio levels" },
  { field: "Video codec", filter: "text", show: false, title: "Video codec" },
  { field: "Video length", filter: "text", show: false, title: "Video length" },
  {
    field: "HTML5 file request",
    filter: "text",
    show: false,
    title: "HTML5 file request",
  },
  { field: "Animation", filter: "text", show: false, title: "Animation" },
  { field: "Framerate", filter: "text", show: false, title: "Framerate" },
  { field: "Bitrate", filter: "text", show: false, title: "Bitrate" },
  { field: "Streaming", filter: "text", show: false, title: "Streaming" },
  {
    field: "Sound when streamning",
    filter: "text",
    show: false,
    title: "Sound when streamning",
  },
  {
    field: "URL activation",
    filter: "text",
    show: false,
    title: "URL activation",
  },
  {
    field: "Comment",
    filter: "text",
    show: false,
    title: "Comment",
  },
];
