import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuItem,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
} from "@progress/kendo-react-grid";
import React from "react";

/**
 * Component for column menu that has column filter functionality and the option to
 * toggle column visibility.
 */
export const CustomColumnMenu = (props) => {
  const [columns, setColumns] = React.useState(props.columns);
  const [columnsExpanded, setColumnsExpanded] = React.useState(false);
  const gridData = props.data;

  const onToggleColumn = (id) => {
    const newColumns = columns.map((column, idx) => {
      return idx === id ? { ...column, show: !column.show } : column;
    });
    setColumns(newColumns);
   
    //Datalayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "column_change",
      columns: newColumns
    });
  };

  const onReset = (event) => {
    event.preventDefault();
    const newColumns = props.columns.map((col) => {
      return { ...col, show: true };
    });
    setColumns(newColumns);
    props.onColumnsSubmit(newColumns);

    if (props.onCloseMenu) {
      props.onCloseMenu();
    }

    //Datalayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "reset",
    });
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.onColumnsSubmit(columns);

    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };
  const onMenuItemClick = () => {
    const value = !columnsExpanded;
    setColumnsExpanded(value);
    
    //Datalayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "menu_item_click",
    });
  };

  const oneVisibleColumn = columns.filter((c) => c.show).length === 1;

  return (
    <div>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem
          title={"Column Picker"}
          iconClass={"k-i-columns"}
          onClick={onMenuItemClick}
        />
        <GridColumnMenuItemContent show={columnsExpanded}>
          <div className={"k-column-list-wrapper"}>
            <form onSubmit={onSubmit} onReset={onReset}>
              <div className={"k-column-list"}>
                {columns.map((column, idx) => (
                  <div key={idx} className={"k-column-list-item"}>
                    <span>
                      <input
                        id={`column-visiblity-show-${idx}`}
                        className="k-checkbox k-checkbox-md k-rounded-md"
                        type="checkbox"
                        readOnly={true}
                        disabled={column.show && oneVisibleColumn}
                        checked={column.show}
                        onClick={() => {
                          onToggleColumn(idx);

                        }
                      
                      }
                      />
                      <label
                        htmlFor={`column-visiblity-show-${idx}`}
                        className="k-checkbox-label"
                        style={{
                          userSelect: "none",
                        }}
                      >
                        {column.title}
                      </label>
                    </span>
                  </div>
                ))}
              </div>
              <div className={"k-actions k-hstack k-justify-content-stretch"}>
                <button
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  }
                >
                  Save
                </button>
                <button
                  type={"reset"}
                  className={
                    "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  }
                >
                  Show all columns
                </button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
      <GridColumnMenuCheckboxFilter
        {...props}
        data={gridData}
        {...gridData}
        expanded={true}
      />
    </div>
  );
};
